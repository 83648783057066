import Icon from './Icon';

const FONT_ICON = (
  <svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.3728 13.0387C16.3658 13.0418 16.3557 13.0477 16.3434 13.0613C14.6876 14.9002 13.6477 17.8149 13.0539 20.9428C12.9436 21.5241 12.3828 21.9059 11.8015 21.7955C11.2201 21.6852 10.8383 21.1244 10.9487 20.5431C11.5659 17.2922 12.6985 13.9069 14.751 11.6274C15.6355 10.6451 17.1602 10.645 18.0449 11.6274C20.0974 13.9066 21.23 17.2921 21.8471 20.5428C21.9575 21.1242 21.5757 21.6849 20.9943 21.7953C20.413 21.9057 19.8522 21.5239 19.7419 20.9425C19.1481 17.8148 18.1083 14.9 16.4525 13.0613C16.4402 13.0477 16.4301 13.0418 16.4231 13.0387C16.4153 13.0353 16.4068 13.0335 16.398 13.0335C16.3891 13.0335 16.3806 13.0353 16.3728 13.0387Z" />
    <path d="M11.1503 7.06534C10.8737 6.76762 10.424 6.76775 10.1477 7.06516C7.11845 10.3259 5.2809 15.5075 4.24925 20.9417C4.13889 21.523 3.57814 21.9048 2.99679 21.7945C2.41544 21.6841 2.03363 21.1234 2.144 20.542C3.19865 14.9866 5.13116 9.31668 8.57778 5.60669C9.70208 4.39647 11.5962 4.39694 12.7202 5.60682C13.3831 6.32035 13.9878 7.10395 14.5395 7.94109C14.8651 8.43518 14.7286 9.09968 14.2345 9.42529C13.7404 9.7509 13.0759 9.61433 12.7503 9.12024C12.256 8.37028 11.7234 7.68215 11.1503 7.06534Z" />
    <path d="M20.1465 19.0745L12.6563 19.0745L12.6562 16.9316L20.1465 16.9316L20.1465 19.0745Z" />
    <path d="M4 16C4 15.4477 4.68392 15 5.52757 15L12.4724 15C13.3161 15 14 15.4477 14 16C14 16.5523 13.3161 17 12.4724 17L5.52757 17C4.68392 17 4 16.5523 4 16Z" />
  </svg>
);

export default function IcFontSize({className}) {
  return (
    <Icon className={className} label="Font">
      {FONT_ICON}
    </Icon>
  );
}

IcFontSize.propTypes = {
  className: Icon.propTypes.className,
};

IcFontSize.defaultProps = {
  className: '',
};
